@import "./Topbar/index.css";
@import "./Career/index.css";
@import "./Milestone/index.css";
@import "./Countries/index.css";
@import "./Articles/index.css";
@import "./Contact/index.css";
@import "./colors.css";

.Blue {
   color: var(--color-blue);
}
.Green {
   color: var(--color-green);
}
.Turquoise {
   color: var(--color-turquoise);
}
.Pink {
   color: var(--color-pink);
}
.Red {
   color: var(--color-red);
}
.Orange {
   color: var(--color-orange);
}
.Purple {
   color: var(--color-light-purple);
}
.Yellow {
   color: var(--color-yellow);
}

.Desktop .MainLine {
   font-size: 24px;
   line-height: 34px;
   font-weight: 800;
   text-decoration: underline;
   color: #0a3f5a;
   margin-bottom: 30px;
   /* display: flex;
   justify-content: space-around; */
}

.Desktop .MainLine i {
   text-decoration: none;
}

.CenterText {
   text-align: center;
}
