:root {
   --color-black: #2f3b55;
   --color-bg-lightest: #f0f1f2;
   --color-bg: #f6fbff;
   --color-purple: #a4036f;
   --color-light-purple: #c55ea3;
   --color-yellow: #ffbe0b;
   --color-pink: #e40066;
   --color-green: #49beaa;
   --color-turquoise: #3ed1d2;
   --color-blue: #1481ba;
   --color-orange: #e53d00;
   --color-red: #ca054d;

   --box-shadow: rgba(136, 152, 170, 0.1) 0px 0px 0px 1px,
      rgba(49, 49, 93, 0.1) 0px 15px 35px 0px,
      rgba(0, 0, 0, 0.08) 0px 5px 15px 0px;

   --box-shadow-inset: 0 1px 1px rgba(0, 0, 0, 0.2),
      0 0 3px rgba(0, 0, 0, 0.1) inset;
}
