.Contact .Desktop {
}

.Contact .Desktop .Social {
   position: absolute;
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   cursor: pointer;
}
.Contact .Desktop .Footer {
   position: absolute;
   width: 100%;
   bottom: 0;
   padding-bottom: 20px;
   display: flex;
   justify-content: center;
   align-items: center;
}

.Contact .Desktop .Social .Icon {
   padding: 1em;
   font-size: 32px;
}
